@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .animation-delay-2000 {
        animation-delay: 2s;
    }
    .animation-delay-4000 {
        animation-delay: 4s;
    }
}